.Scroll-indicator {
    position: fixed;
    bottom: 20px;
    right: 50%;
    width: 40px;
    height: 40px;
    background-color: transparent;
    color: #F90;
    border: 2px solid #F90;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
    cursor: default;
}

.Scroll-indicator.hide {
    opacity: 0;
}
@font-face {
    font-family: 'Dosis';
    src: local(''),
    url(./../../components/Fonts/Dosis-VariableFont_wght.ttf)
}

.Music {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #1A1A1A;
    overflow-y: auto;
}

.Music-top-block {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.Music-bottom-block {
    flex: 7;
    display: flex;
    flex-direction: row;
}

.Music-top-block-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 10px 0;
    width: 100%;
}

.Music-top-block-text h1 {
    color: #F90;
    font-family: Dosis;
    font-size: 100px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.Music-bottom-block-raw {
    box-sizing: border-box;
    flex: 1;
}

.Music-bottom-block-raw-text {
    display: flex;
    align-items: center;
    height: 100%;
    margin: auto 30px;
}

.Music-bottom-block-raw-text p {
    color: #F90;
    font-family: Dosis;
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Music-bottom-block-raw-image {
    width: 100%;
    height: 100%;
    background-image: url('./..//../images/music_img.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
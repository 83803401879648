@font-face {
    font-family: 'Dosis';
    src: local(''),
    url(./../../components/Fonts/Dosis-VariableFont_wght.ttf)
}

.Education {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #1A1A1A;
}

.Education-top-block {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.Education-bottom-block {
    flex: 7;
    display: flex;
    flex-direction: row;
    height: 100%;
}

.Education-top-block-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 10px 0;
    width: 100%;
}

.Education-top-block-text h1 {
    color: #F90;
    font-family: Dosis;
    font-size: 100px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.Education-bottom-block-raw {
    box-sizing: border-box;
    flex: 1;
    margin-right: 0;
}

.Education-bottom-block-raw-text {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0px;
    justify-content: flex-start;
    padding-left: 30px;
    background-color: #1A1A1A;
    padding-right: 15px;
}

.Education-bottom-block-raw-text-English, .Traveling-bottom-block-raw-text-1-4, .Traveling-bottom-block-raw-text-5-7, .Education-bottom-block-raw-text-polish,  .Education-bottom-block-raw-text-8, .Education-bottom-block-raw-text-program{
    justify-content: flex-start;
    padding-left: 0px;
}

.Education-bottom-block-raw-text p {
    color: #F90;
    font-family: Dosis;
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.Education-bottom-block-raw-image {
    width: 100%;
    height: 100%;
    background-image: url('./..//../images/education1_img.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;
}

.Education-bottom-block-raw-image-2 {
    width: 100%;
    height: 100%;
    background-image: url('./..//../images/education2_img.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;
}

.Education-bottom-block-raw-image-3 {
    width: 100%;
    height: 100%;
    background-image: url('./..//../images/education3_img.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;
}

.Education-bottom-block-raw-image-4 {
    width: 100%;
    height: 100%;
    background-image: url('./..//../images/education4_img.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;
}

.Education-bottom-block-raw-image-5 {
    width: 100%;
    height: 100%;
    background-image: url('./..//../images/education5_img.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;
}

.Education-bottom-block-raw-image-6 {
    width: 100%;
    height: 100%;
    background-image: url('./..//../images/education6_img.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;
}

.Education-bottom-block-raw-image, .Education-bottom-block-raw-image-2, .Education-bottom-block-raw-image-3, .Education-bottom-block-raw-image-4, .Education-bottom-block-raw-image-5, .Education-bottom-block-raw-image-6 {
    background-color: #1A1A1A;
}
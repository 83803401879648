.Menu-container {
    position: relative;
    margin: 15px;
    width: fit-content;
}

.Menu-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
    width: fit-content;
}

.Menu-icon:hover {
    outline: 2px solid #fff;
    outline-offset: 2px;
    border-radius: 20%;
}

.Menu-list {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #e6e6e6;
    box-shadow: 0 2px 5px rgba(81, 81, 81, 0.1);
    border-radius: 4px;
    z-index: 1;
}

.Menu-list li {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    color: #1A1A1A;
    font-family: Dosis;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 55px */
    white-space: nowrap;

}

.Menu-list li:hover {
    background-color: #fff;
}

.Submenu {
    position: relative;
}

.Submenu-list {
    display: block;
    position: absolute;
    padding: 0;
    margin: 0;
    top: 0;
    left: 100%;
    background-color: #e6e6e6;
    box-shadow: 0 2px 5px rgba(81, 81, 81, 0.1);
    border-radius: 4px;
    z-index: 1;
}

.Menu-container.open .Menu-list {
    display: block;
}

.Menu-item.checked {
   text-decoration-color: #1A1A1A;
   text-decoration-line: underline;
   text-decoration-thickness: 2px;
   text-underline-offset: 12px;
}
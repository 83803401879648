@font-face {
    font-family: 'Dosis';
    src: local(''),
    url(./../../components/Fonts/Dosis-VariableFont_wght.ttf)
}

.Main {
    margin: 0;
    padding: 0;
    display: flex;
    height: 100vh;
    background-image: url('./..//../images/main_img.svg');
    background-color: lightgray;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.Main-block {
    box-sizing: border-box;
    flex: 1;

}

.Main-block:nth-child(1) {
    background: linear-gradient(91deg, #1A1A1A 62.18%, rgba(26, 26, 26, 0.00) 86.47%, rgba(26, 26, 26, 0.00) 346.84%);
}

.Main-block:nth-child(2) {
    background: linear-gradient(268deg, #1A1A1A 60.12%, rgba(26, 26, 26, 0.00) 89.15%, rgba(26, 26, 26, 0.00) 346.84%);
}

.Main-text-left, .Main-text-right {
    display: flex;
    align-items: center;
    height: 100vh;
    margin: 0;
}

.Main-text-left {
    justify-content: flex-start;
    padding-left: 30px;
    height: calc(100% - 254px);
}

.Main-text-right {
    justify-content: flex-end;
    padding-right: 30px;
}

.Main-text-left, .Main-text-right p {
    color: #FFF;
    text-align: center;
    font-family: Dosis;
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


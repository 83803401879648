@font-face {
    font-family: 'Dosis';
    src: local(''),
    url(./../../components/Fonts/Dosis-VariableFont_wght.ttf)
}

.Achievements {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #1A1A1A;
}

.Achievements-top-block {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.Achievements-bottom-block {
    flex: 7;
    display: flex;
    flex-direction: row;
}

.Achievements-top-block-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 10px 0;
    width: 100%;
}

.Achievements-top-block-text h1 {
    color: #F90;
    font-family: Dosis;
    font-size: 100px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.Achievements-bottom-block-raw {
    box-sizing: border-box;
    flex: 1;
}

.Achievements-bottom-block-raw-text {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-start;
    padding-left: 45px;
    background-color: #1A1A1A;
}

.Achievements-bottom-block-raw-text-2 {
    display: flex;
    align-items: center;
    height: 100%;
    margin: auto 30px;
    justify-content: flex-end;
    padding-left: 30px;
}

.Achievements-bottom-block-raw-text-Aikido, .Traveling-bottom-block-raw-text-basketball {
    justify-content: flex-start;
    padding-left: 0px;
}

.Achievements-bottom-block-raw-text p {
    color: #F90;
    font-family: Dosis;
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Achievements-bottom-block-raw-image {
    width: 100%;
    height: 100%;
    background-image: url('./..//../images/ach_aikido_img.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    
}

.Achievements-bottom-block-raw-image-2 {
    width: 100%;
    height: 100%;
    background-image: url('./..//../images/ach_programming_img.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #1A1A1A;
}